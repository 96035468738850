import type { Notification, NotificationSchema } from './types'
import events from './events'

let count = 0

const generateId = () => {
  const id = count
  count += 1
  return id
}

type JbNotification<T extends NotificationSchema> = Omit<
  Notification<T>,
  'id' | 'group' | 'timeout'
> &
  Partial<Pick<Notification<T>, 'group'>>;

const createNotifier = <T extends NotificationSchema>() => {
  return (notification: JbNotification<T>, timeout?: number) => {
    const identifiedNotification = {
      ...notification,
      id: generateId(),
      group: notification.group || ''
    } as Notification<T>;
    events.emit('notify', { notification: identifiedNotification, timeout })

    return () => events.emit('close', identifiedNotification.id)
  }
}

export default createNotifier
