import type { ComputedRef, InjectionKey, Ref } from 'vue';

export const SHARED_DATA = Symbol('SHARED_DATA') as InjectionKey<{
  fingerprintToken: Ref<string>;
  usersCount: Ref<number>;
  wageredAmount: Ref<number>;
}>;

export const INIT_LOADING = Symbol('INIT_LOADING') as InjectionKey<{
  initialRendering: Ref<boolean>;
  mounted: Ref<boolean>;
  ready: ComputedRef<boolean>;
  loadings: Ref<string[]>;
  addLoading: (maxTimeout?: number) => () => void;
  useAddLoading: (maxTimeout?: number) => {
    add: () => void;
    remove: (timeout?: number) => void;
  };
  onInitialRendering: (callback: () => void) => void;
}>;
