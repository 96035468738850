<script lang="ts" setup>
import { FINISH_REGISTRATION, FORGOT_PASSWORD, LOGIN, NEEDS_MERGE, RESET_PASSWORD, WELCOME_USER } from '~/constants';
import LoginForm from '~/components/pages/chip-promo/organisms/Auth/LoginForm.vue';
import RegisterForm from '~/components/pages/chip-promo/organisms/Auth/RegisterForm.vue';
import ForgotPassword from '~/components/pages/chip-promo/organisms/Auth/ForgotPassword.vue';
import ResetPassword from '~/components/pages/chip-promo/organisms/Auth/ResetPassword.vue';

const { modalVisible, type, me } = defineProps<{
  modalVisible: boolean;
  type: string;
  me: any;
}>();

const emits = defineEmits<{
  (e: 'close:loginModal', payload: boolean): void;
}>();

const handleModalClose = () => {
  emits('close:loginModal', false);
};

useHead({
  script: [
    {
      defer: true,
      children: type === WELCOME_USER ? `gtag('config', 'G-MWQ9HKV8Y1', {'user_id': '${me?.username || ''}'});` : ``,
    },
  ],
});
</script>

<template>
  <JbSimpleModal
    :is-modal="type === FINISH_REGISTRATION"
    :is-open="modalVisible"
    :no-header="false"
    class="inline-flex justify-center items-start md:items-center"
    class-name="w-[480px] h-full bg-chip mt-12 md:mt-0 px-[26px] py-[26px]"
    @close:simple-modal="handleModalClose"
  >
    <template #header>
      <div
        v-if="type === LOGIN"
        class="font-bold"
      >
        Login
      </div>
      <div
        v-else-if="type === RESET_PASSWORD"
        class="font-bold"
      >
        Reset Password
      </div>
      <div
        v-else-if="type === FORGOT_PASSWORD"
        class="font-bold"
      >
        Forgot Password
      </div>
      <div
        v-else-if="type === FINISH_REGISTRATION"
        class="font-bold"
      >
        Finish Registering
      </div>
      <div
        v-else-if="type === NEEDS_MERGE"
        class="font-bold"
      >
        Existing Account Detected
      </div>
      <div
        v-else-if="type === WELCOME_USER"
        class="font-bold text-highlights text-xl"
      >
        Welcome {{ me?.username }},
      </div>
    </template>

    <LoginForm
      v-if="type === LOGIN"
      @close="handleModalClose"
    />

    <RegisterForm
      v-else-if="type === FINISH_REGISTRATION"
      @close="handleModalClose"
    />

    <ForgotPassword
      v-else-if="type === FORGOT_PASSWORD"
    />

    <ResetPassword
      v-else-if="type === RESET_PASSWORD"
    />

    <div
      v-else-if="type === NEEDS_MERGE"
      class="text-paragraph text-left"
    >
      We've noticed the email address you're attempting to register with is already associated with an existing account.
      If this account belongs to you, you can choose to sign in using our login form instead.
      In case you've forgotten your password, you can utilize the "Forgot Password" option to reset it.
      Otherwise, please consider using a different email address to create a new account. Thank you!
    </div>

    <div
      v-else-if="type === WELCOME_USER"
      id="registration_success"
      class="text-white text-left"
    >
      Your account has been created - you can now log in to spin and manage your referrals. Be sure to check back
      often for pre-launch giveaways and promos!
    </div>

    <div
      v-else
      class="text-amber-600 font-bold text-lg"
    >
      Something went wrong! Please try again!
    </div>
  </JbSimpleModal>
</template>
