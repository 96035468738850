export const ERRORS = {
  PASSWORD_REQUIRED: 'Enter a password with at least 8 characters, 1 symbol, 1 number.',
  PASSWORD_MIN_LENGTH: 'Min. 8 characters.',
  PASSWORD_SYMBOL_NUMBER: 'Min. 1 symbol and number.',
  PASSWORD_LETTERS: 'Upper and lower case letters.',
  USERNAME_MIN_LENGTH: 'Username must be at least 3 characters in length.',
  USERNAME_MAX_LENGTH: 'Username must be less than 14 characters in length.',
  USERNAME_INVALID: 'Contains invalid characters.',
  USERNAME_REQUIRED: 'PRE-REGISTER your username - Hurry before someone else takes it!',
  EMAIL_AT_LETTER_REQUIRED: 'Must include @ symbol.',
  EMAIL_DOT_LETTER_REQUIRED: 'Must include a period.',
  EMAIL_DOMAIN_NOT_SUPPORTED: 'Your email domain is not supported, please try with a different email address.',
  EMAIL_INVALID: 'Enter a valid email address.',
  EMAIL_REQUIRED: 'Enter your email address.',
  EMAIL_MIN_LENGTH: 'Email address must be at least 3 characters in length.',
  LOGIN_PASSWORD_MIN_LENGTH: 'Password must be at least 8 characters in length.',
  WHITELIST_ADDRESS_NAME_REQUIRED: 'Input a label name for the address.',
  WHITELIST_ADDRESS_NAME_MAX_LENGTH: 'Max 15 characters in length.',
  WHITELIST_ADDRESS_REQUIRED: 'Enter your physical address to continue.',
  INVALID_WHITELIST_ADDRESS: 'Invalid address, please double check and try again.',
  TIP_USERNAME_MIN_LENGTH: 'Must be at least 3 characters in length.',
  TIP_USERNAME_MAX_LENGTH: 'Must be less than 14 characters in length.',
  PROMO_CODE_REQUIRED: 'Code is required.',
  ADDRESS_INPUT: 'My awesome address that will remain a hashed secret.',
};
