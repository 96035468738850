import FingerprintJS from '@fingerprintjs/fingerprintjs';

export default async function() {
  const visitorToken = ref<string>('');

  if (process.client) {
    const fp = await FingerprintJS.load();
    const { visitorId } = await fp.get();

    visitorToken.value = visitorId;
  }

  return {
    visitorToken: visitorToken.value,
  };
}
