<script setup lang="ts">
import NotFound from '~/components/molecules/errors/404.vue'

defineProps({
  error: Object
})

const router = useRouter();
const exceptRoutes = ['/luckychip'];
</script>

<template>
  <div
    v-if="!exceptRoutes.includes(router.currentRoute.value.path)"
    class="font-semibold"
  >
    <div v-if="error?.statusCode === 404">
      <NotFound />
    </div>
    <div v-else>
      <div class="p-4">
        <h2>{{ error?.message }}</h2>
        <pre v-html="error?.stack" />
      </div>
    </div>
  </div>
</template>
