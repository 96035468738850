import { addressFields, tempUserFields, userFields } from './fragments';

export const USER_COUNT_SUBSCRIPTION = gql`
  subscription userCountUpdated {
    userCountUpdated {
      players_count
      wagered
    }
  }
`;

export const SEND_VERIFICATION_CODE = gql`
  mutation sendVerificationCode(
    $email: String!
    $fingerprint: String!
    $affiliateCode: String
  ) {
    sendVerificationCode(
      email: $email
      fingerprint: $fingerprint
      affiliateCode: $affiliateCode
    ) {
      status
      message
      tempUser {
        ...tempUserFields
      }
    }
  }
  ${tempUserFields}
`;

export const VALIDATE_EMAIL_CODE = gql`
  mutation validateEmailCode($id: ID!, $code: String!) {
    validateEmailCode(id: $id, code: $code) {
      status
      message
      tempUser {
        ...tempUserFields
      }
    }
  }
  ${tempUserFields}
`;

export const CREATE_USER_AUTH = gql`
  mutation createUserAndAuth(
    $id: ID!
    $username: String!
    $password: String!
    $os: String
    $browser: String
    $ip: String
    $device: String
    $isVisitor: Boolean
  ) {
    createUserAndAuth(
      id: $id
      username: $username
      password: $password
      os: $os
      browser: $browser
      ip: $ip
      device: $device
      isVisitor: $isVisitor
    ) {
      status
      message
      me {
        ...userFields
        address {
          ...addressFields
        }
        wallets {
          id
          available_balance
          balance
          currency_code
        }
        fingerprints {
          id
          fingerprint
        }
        sponsorCodes {
          id
          code
        }
      }
      token
    }
  }
  ${userFields}
  ${addressFields}
`;
