import { default as aboutusJGEkGXRkRZMeta } from "/opt/buildhome/repo/src/pages/aboutus.vue?macro=true";
import { default as indexhGpynX1cdDMeta } from "/opt/buildhome/repo/src/pages/index.vue?macro=true";
import { default as policiesNvHEeyh9Z6Meta } from "/opt/buildhome/repo/src/pages/policies.vue?macro=true";
import { default as _91code_93FYA6bR3WyeMeta } from "/opt/buildhome/repo/src/pages/promo/[code].vue?macro=true";
import { default as termsbOpDTp1t6dMeta } from "/opt/buildhome/repo/src/pages/terms.vue?macro=true";
import { default as component_45stubqUvqUM3fNKMeta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: aboutusJGEkGXRkRZMeta?.name ?? "aboutus",
    path: aboutusJGEkGXRkRZMeta?.path ?? "/aboutus",
    meta: aboutusJGEkGXRkRZMeta || {},
    alias: aboutusJGEkGXRkRZMeta?.alias || [],
    redirect: aboutusJGEkGXRkRZMeta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/aboutus.vue").then(m => m.default || m)
  },
  {
    name: indexhGpynX1cdDMeta?.name ?? "index",
    path: indexhGpynX1cdDMeta?.path ?? "/",
    meta: indexhGpynX1cdDMeta || {},
    alias: indexhGpynX1cdDMeta?.alias || [],
    redirect: indexhGpynX1cdDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: policiesNvHEeyh9Z6Meta?.name ?? "policies",
    path: policiesNvHEeyh9Z6Meta?.path ?? "/policies",
    meta: policiesNvHEeyh9Z6Meta || {},
    alias: policiesNvHEeyh9Z6Meta?.alias || [],
    redirect: policiesNvHEeyh9Z6Meta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/policies.vue").then(m => m.default || m)
  },
  {
    name: _91code_93FYA6bR3WyeMeta?.name ?? "promo-code",
    path: _91code_93FYA6bR3WyeMeta?.path ?? "/promo/:code()",
    meta: _91code_93FYA6bR3WyeMeta || {},
    alias: _91code_93FYA6bR3WyeMeta?.alias || [],
    redirect: _91code_93FYA6bR3WyeMeta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/promo/[code].vue").then(m => m.default || m)
  },
  {
    name: termsbOpDTp1t6dMeta?.name ?? "terms",
    path: termsbOpDTp1t6dMeta?.path ?? "/terms",
    meta: termsbOpDTp1t6dMeta || {},
    alias: termsbOpDTp1t6dMeta?.alias || [],
    redirect: termsbOpDTp1t6dMeta?.redirect,
    component: () => import("/opt/buildhome/repo/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: component_45stubqUvqUM3fNKMeta?.name ?? undefined,
    path: component_45stubqUvqUM3fNKMeta?.path ?? "/login",
    meta: component_45stubqUvqUM3fNKMeta || {},
    alias: component_45stubqUvqUM3fNKMeta?.alias || [],
    redirect: component_45stubqUvqUM3fNKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubqUvqUM3fNKMeta?.name ?? undefined,
    path: component_45stubqUvqUM3fNKMeta?.path ?? "/signin",
    meta: component_45stubqUvqUM3fNKMeta || {},
    alias: component_45stubqUvqUM3fNKMeta?.alias || [],
    redirect: component_45stubqUvqUM3fNKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubqUvqUM3fNKMeta?.name ?? undefined,
    path: component_45stubqUvqUM3fNKMeta?.path ?? "/register",
    meta: component_45stubqUvqUM3fNKMeta || {},
    alias: component_45stubqUvqUM3fNKMeta?.alias || [],
    redirect: component_45stubqUvqUM3fNKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubqUvqUM3fNKMeta?.name ?? undefined,
    path: component_45stubqUvqUM3fNKMeta?.path ?? "/signup",
    meta: component_45stubqUvqUM3fNKMeta || {},
    alias: component_45stubqUvqUM3fNKMeta?.alias || [],
    redirect: component_45stubqUvqUM3fNKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubqUvqUM3fNKMeta?.name ?? undefined,
    path: component_45stubqUvqUM3fNKMeta?.path ?? "/luckychip",
    meta: component_45stubqUvqUM3fNKMeta || {},
    alias: component_45stubqUvqUM3fNKMeta?.alias || [],
    redirect: component_45stubqUvqUM3fNKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: component_45stubqUvqUM3fNKMeta?.name ?? undefined,
    path: component_45stubqUvqUM3fNKMeta?.path ?? "/sitemap.xml",
    meta: component_45stubqUvqUM3fNKMeta || {},
    alias: component_45stubqUvqUM3fNKMeta?.alias || [],
    redirect: component_45stubqUvqUM3fNKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]