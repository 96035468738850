import { addressFields, userFields } from './fragments';

export const USERS_COUNT = gql`
  query getSharedData {
    getSharedData {
      players_count
      wagered
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation login(
    $email: String
    $password: String!
    $username: String
    $code: String
    $isLogin: Boolean
  ) {
    login(
      email: $email
      password: $password
      username: $username
      code: $code
      isLogin: $isLogin
    ) {
      token
      user {
        ...userFields
      }
    }
  }
  ${userFields}
`;

export const LOGOUT_MUTATION = gql`
  mutation logout {
    logout
  }
`;

export const CLIENTSEED_MUTATION = gql`
  mutation UpdateClientSeed($gameId: Int, $input: String) {
    updateClientSeed(gameId: $gameId, input: $input) {
      id
      client_seed
      binary_seed
      is_current
      nonce
      created_at
      updated_at
    }
  }
`;

export const USER_UPDATED_SUBSCRIPTION = gql`
  subscription userUpdated {
    userUpdated {
      ...userFields
      address {
        ...addressFields
      }
      wallets {
        id
        available_balance
        balance
        currency_code
        fiatquotes
      }
      fingerprints {
        id
        fingerprint
      }
      sponsorCodes {
        id
        code
      }
      is_banned {
        comment
        expired_at
      }
      __typename
    }
  }
  ${userFields}
  ${addressFields}
`;

export const ME_QUERY = gql`
  query me {
    me {
      ...userFields
      address {
        ...addressFields
      }
      wallets {
        id
        available_balance
        balance
        currency_code
        fiatquotes
      }
      fingerprints {
        id
        fingerprint
      }
      sponsorCodes {
        id
        code
      }
      clientSeeds {
        client_seed
        game_id
        is_current
      }
    }
  }
  ${userFields}
  ${addressFields}
`;

export const GEOCODE_ADDRESS_QUERY = gql`
  query geocodeAddress($input: String!) {
    geocodeAddress(input: $input) {
      ...addressFields
    }
  }
  ${addressFields}
`;

export const SAVE_ADDRESS_MUTATION = gql`
  mutation saveAddress(
    $formatted_address: String!
    $latitude: Float!
    $longitude: Float!
    $number: String
    $street: String
    $postalcode: String
    $subLocality: String
    $country_id: Int!
    $state_id: Int!
    $city_id: Int!
  ) {
    saveAddress(
      input: {
        formatted_address: $formatted_address
        latitude: $latitude
        longitude: $longitude
        number: $number
        street: $street
        postalcode: $postalcode
        subLocality: $subLocality
        country_id: $country_id
        state_id: $state_id
        city_id: $city_id
      }
    ) {
      ...addressFields
    }
  }
  ${addressFields}
`;

export const GET_SOCIAL_URL_QUERY = gql`
  query getSocialContent($provider: SocialProvider!) {
    getSocialContent(provider: $provider) {
      url
    }
  }
`;

export const GET_SPONSOR_CODE_QUERY = gql`
  query createOrGetSponsorCode {
    createOrGetSponsorCode {
      id
      code
      percent_back
      sponsor_id
      total_affiliates
      usage_count
      usage_limit
    }
  }
`;

export const APPLY_PROMO_CODE = gql`
  mutation ApplyPromoCode($code: String!) {
    applyPromoCode(code: $code) {
      id
      code
      type
      value
      is_enabled
      quantity
      limit
      starts_at
      expires_at
      redeem_result
      data
      currency_code
    }
  }
`;

export const LOGIN_SOCIAL_MUTATION = gql`
  mutation loginSocial($hash: String!) {
    loginSocial(hash: $hash) {
      token
      socialResponse {
        email
        needsMerge
        socialiteId
        userId
        wasRecentlyCreated
      }
      user {
        ...userFields
        incomplete_profile
      }
    }
  }
  ${userFields}
`;

export const FINISH_REGISTRATION_MUTATION = gql`
  mutation finishRegistration(
    $hash: String!
    $email: String!
    $username: String!
    $password: String!
    $fingerprint: String
    $sponsorCode: String
    $isVisitor: Boolean
  ) {
    finishRegistration(
      hash: $hash
      input: {
        email: $email
        username: $username
        password: $password
        fingerprint: $fingerprint
        sponsorCode: $sponsorCode
        isVisitor: $isVisitor
      }
    ) {
      token
      user {
        ...userFields
      }
    }
  }
  ${userFields}
`;

export const SEND_RESET_LINK_MUTATION = gql`
  mutation sendResetLink($email: String!) {
    sendResetLink(email: $email)
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword(
    $email: String!
    $password: String!
    $password_confirmation: String!
    $token: String!
    $code: String
  ) {
    resetPassword(
      input: {
        email: $email
        password: $password
        password_confirmation: $password_confirmation
        token: $token
        code: $code
        check_tfa: false
      }
    )
  }
`;
