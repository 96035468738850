<script setup lang="ts">
import dayjs from 'dayjs';
import type { User } from '~/types';
import { AUTH, INIT_LOADING, LOGIN, SHARED_DATA } from '~/constants';
import { LOGOUT_MUTATION, ME_QUERY, USER_UPDATED_SUBSCRIPTION } from '~/graphql';
// @ts-expect-error
import Apollo from '#apollo';
import LoginModal from '~/components/pages/chip-promo/organisms/Auth/LoginModal.vue';
import { notify } from '~/atoms';

const { mutate, onDone, onError } = useMutation(LOGOUT_MUTATION);
const { onLogout } = useApollo();

const clientConfig = Apollo.clients.default;
const { fingerprintToken }: any = inject(SHARED_DATA);
const { onInitialRendering, addLoading }: any = inject(INIT_LOADING)!;
const cookie = useCookie(clientConfig.tokenName);
const { loading, result, options, subscribeToMore, refetch } = useQuery(
  ME_QUERY,
  {},
  {
    errorPolicy: process.server ? 'ignore' : 'all',
    enabled: !!cookie.value,
    notifyOnNetworkStatusChange: true,
  },
);

const route = useRoute();
const router = useRouter();
const runtimeConfig = useRuntimeConfig();
const turnstileToken = ref(runtimeConfig.public.appEnv === 'local');
const hideTurnstile = computed(() => runtimeConfig.public.appEnv === 'local');

const authType = computed(() => route.query.type);
const isVisibleModal = computed(() => route.query.modal === 'auth');
const me = computed(() => result.value?.me || null);
const isLoggedIn = computed(() => !!me?.value);
const visitorId = computed(() => fingerprintToken.value);
const username = ref<string>('');
const password = ref<string>('');
const affiliateCode = computed(() => route.query.p || '');
const isVisibleProfile = ref<boolean>(false);

const login = async () => {
  cookie.value = useCookie(clientConfig.tokenName).value;
  // @ts-ignore
  options.enabled = true;
  refetch();
  router.push({
    path: router.currentRoute.value.path,
  });
};

const logout = async () => {
  await mutate();
};

onDone(async () => {
  cookie.value = null;

  await onLogout();
});

const openAuthModal = () => {
  router.push({
    path: router.currentRoute.value.path,
    query: {
      ...router.currentRoute.value.query,
      type: LOGIN,
      modal: 'auth',
    },
  });
};

onInitialRendering(() => {
  if (loading.value) {
    const removeLoading = addLoading();
    const stopWatch = watch(
      () => loading.value,
      (isLoading) => {
        if (!isLoading) {
          removeLoading();
          stopWatch();
        }
      },
    );
  }
});

const onCloseModal = () => {
  if (!hideTurnstile.value) {
    turnstileToken.value = false;
  }

  router.push({
    path: router.currentRoute.value.path,
  });
};

provide(AUTH, {
  authType,
  username,
  password,
  affiliateCode,
  loading,
  isVisibleProfile,
  isVisibleModal,
  isLoggedIn,
  visitorId,
  me,
  turnstileToken,
  hideTurnstile,
  login,
  refetch,
  openAuthModal,
});

const subscribeMeQuery = () => {
  let newMeData: User;
  subscribeToMore({
    document: USER_UPDATED_SUBSCRIPTION,
    variables: {},
    updateQuery (prev, { subscriptionData }) {
      const updatedUser = subscriptionData.data?.userUpdated;

      if (updatedUser.is_banned) {
        logout();
        const reason = updatedUser.is_banned.comment
          ? '. Reason: ' + updatedUser.is_banned.comment
          : '';
        const msg = updatedUser.is_banned.expired_at
          ? 'We suspended your account until ' +
          dayjs(updatedUser.is_banned.expired_at).format('YYYY-MM-DD') +
          reason
          : 'Your account has been suspended indefinitely' + reason;

        notify.warning({
          title: 'Your account has been suspended',
          description: msg,
        });
      }

      if (!updatedUser || updatedUser.id !== prev.me?.id) {
        return prev;
      }

      newMeData = {
        ...updatedUser,
        free_spins: updatedUser.free_spins,
        wallets: [...updatedUser.wallets],
        total_affiliates: updatedUser.total_affiliates,
        __typename: 'User',
      };

      return {
        me: {
          ...prev.me,
          ...newMeData,
          address: updatedUser.address
            ? {
              ...updatedUser.address,
            }
            : {},
          sponsorCodes: [...updatedUser.sponsorCodes],
        },
      };
    },
    onError (err: Error) {
      console.log(err.message);
    },
  });
}

watch(
  () => isLoggedIn.value,
  (val: boolean) => {
    if (!val) {
      return
    }

    subscribeMeQuery()
  },
  { immediate: isLoggedIn.value }
)
</script>

<template>
  <slot />
  <ClientOnly>
    <LoginModal
      v-if="!me || isVisibleModal"
      :type="route.query.type || LOGIN"
      :modal-visible="isVisibleModal"
      :me="me"
      @close:login-modal="onCloseModal"
    />
  </ClientOnly>
</template>
