<script lang="ts" setup>
import { useVuelidate } from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import { useMutation } from '@vue/apollo-composable';
import { EnvelopeIcon, EyeIcon, EyeSlashIcon, LockClosedIcon } from '@heroicons/vue/20/solid';
import JInput from '~/atoms/JInput.vue';
import FormField from '~/components/molecules/form/FormField.vue';
import { AUTH, ERRORS, FORGOT_PASSWORD } from '~/constants';
import { LOGIN_MUTATION } from '~/graphql';
import { CIRCLE_CHECK_ICON, SMALL_CIRCLE_WARNING_ICON } from '~/icons/icons';
import { cn, parseGraphqlError } from '~/utils';
import { notify } from '~/atoms';
import SocialAuth from '~/components/pages/chip-promo/organisms/Auth/SocialAuth.vue';

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const { username, password, login, hideTurnstile }: any = inject(AUTH);
const showPassword = ref<boolean>(false);
const runtimeConfig = useRuntimeConfig();
const form = reactive({
  username: '',
  password: '',
  turnstileToken: hideTurnstile.value || null,
});
const route = useRoute();
const router = useRouter();
const rules = {
  username: {
    required,
    minLength: minLength(3),
  },
  password: {
    required,
    minLength: minLength(8),
  },
  turnstileToken: {
    required,
  },
};
const v$ = useVuelidate(rules, form);
const { onLogin } = useApollo();
const loggedIn = ref<boolean>(false);
const loginLoading = ref<boolean>(false);
const { mutate, loading, onDone, onError } = useMutation(LOGIN_MUTATION);

const onSubmit = async () => {
  if (!(await v$.value.$validate()) || loading.value || loginLoading.value) {
    return;
  }

  loginLoading.value = true;
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  username.value = form.username;
  password.value = form.password;

  pattern.test(form.username)
    ? await mutate({
      email: form.username,
      password: form.password,
      code: '',
      isLogin: false,
    })
    : await mutate({
      username: form.username,
      password: form.password,
      code: '',
      isLogin: false,
    });
};

onDone(async ({ data }) => {
  loggedIn.value = true;
  await onLogin(data.login.token);
  await login();
  loginLoading.value = false;
});

onError((error) => {
  loginLoading.value = false;
  const parsedError = parseGraphqlError(error);
  notify.error({
    title: 'Failed Authentication',
    description: parsedError.message || 'Something went wrong. Please try again!',
  });
});

const onClickForgotPassword = () => {
  loginLoading.value = false;
  router.replace({
    query: {
      ...route.query,
      type: FORGOT_PASSWORD,
    },
  });
};

const inputPassword = async () => {
  await v$.value.password.$validate();
};

const inputEmailOrUsername = async () => {
  await v$.value.username.$validate();
};

const togglePasswordVisible = () => {
  showPassword.value = !showPassword.value;
};

const handleRegister = () => {
  emits('close');
};
</script>

<template>
  <form
    class="z-5 flex-1 mt-2"
    @submit.prevent="onSubmit()"
  >
    <div
      class="w-full h-full"
    >
      <p
        class="
          text-lg md:text-xxl font-bold leading-[145%] text-[#F8F5F6] text-left w-full
        "
      >
        Welcome to Junglebet!
      </p>

      <div class="w-full flex flex-col gap-3 items-start mt-3 text-xs md:text-sm">
        <FormField
          :class="v$.username.$error ? cn('!border-red-600') : (v$.username.$dirty ? cn('!border-highlights') : '')"
          class="w-full hover:border-highlights"
        >
          <JInput
            v-model="form.username"
            placeholder="Email address or Username"
            @update:model-value="inputEmailOrUsername"
          >
            <template #startIcon>
              <EnvelopeIcon
                :class="v$.username.$error ? cn('!text-red-600') : (v$.username.$dirty ? cn('!text-zinc-100') : '')"
                class="w-4 h-4 text-paragraph"
              />
            </template>
          </JInput>
        </FormField>

        <FormField
          :class="v$.password.$error ? cn('!border-red-600') : (v$.password.$dirty ? cn('!border-highlights') : '')"
          class="w-full hover:border-highlights"
        >
          <JInput
            v-model="form.password"
            :type="showPassword ? 'text' : 'password'"
            placeholder="Enter your password"
            @update:model-value="inputPassword"
          >
            <template #startIcon>
              <LockClosedIcon
                :class="v$.password.$error ? cn('!text-red-600') : (v$.password.$dirty ? cn('!text-zinc-100') : '')"
                class="w-4 h-4 text-paragraph"
              />
            </template>
            <template #endIcon>
              <button
                class="w-4 h-4"
                @click="togglePasswordVisible"
              >
                <template v-if="!showPassword">
                  <EyeIcon
                    :class="v$.password.$error ? cn('text-red-600') : (v$.password.$dirty ? cn('text-zinc-100') : '')"
                    class="w-4 h-4 text-paragraph"
                  />
                </template>
                <template v-else>
                  <EyeSlashIcon
                    :class="v$.password.$error ? cn('text-red-600') : (v$.password.$dirty ? cn('text-zinc-100') : '')"
                    class="w-4 h-4 text-paragraph"
                  />
                </template>
              </button>
            </template>
          </JInput>
        </FormField>

        <NuxtTurnstile
          v-if="!hideTurnstile"
          v-model="form.turnstileToken"
          :site-key="runtimeConfig.public.turnstileSiteKey"
        />

        <div
          v-if="v$.username.$error || v$.password.$error || v$.turnstileToken.$error"
          class="w-full flex flex-col gap-2"
        >
          <JbInputMessage v-if="v$.username.$error">
            <svg
              class="w-3 h-3 text-red-600"
            >
              <use :xlink:href="SMALL_CIRCLE_WARNING_ICON" />
            </svg>
            {{
              v$.username.minLength.$invalid
                ? ERRORS.USERNAME_MIN_LENGTH
                : ERRORS.USERNAME_REQUIRED
            }}
          </JbInputMessage>
          <JbInputMessage v-if="v$.password.$error">
            <svg
              class="w-3 h-3 text-red-600"
            >
              <use :xlink:href="SMALL_CIRCLE_WARNING_ICON" />
            </svg>

            {{
              v$.password.minLength.$invalid
                ? ERRORS.LOGIN_PASSWORD_MIN_LENGTH
                : ERRORS.PASSWORD_REQUIRED
            }}
          </JbInputMessage>
          <JbInputMessage v-if="v$.turnstileToken.$error">
            <svg
              class="w-3 h-3 text-red-600"
            >
              <use :xlink:href="SMALL_CIRCLE_WARNING_ICON" />
            </svg>

            Turnstile reCAPTCHA is required.
          </JbInputMessage>
        </div>

        <div class="w-full">
          <div
            v-if="loggedIn"
            class="
              text-white text-base font-weight-bold
              inline-flex items-center justify-center gap-x-2
              h-[54px] w-full
            "
          >
            <span>You are logged in</span>
            <svg
              class="w-5 h-5 text-green-600"
            >
              <use :xlink:href="CIRCLE_CHECK_ICON" />
            </svg>
          </div>
          <JbButton
            v-else
            :disabled="!hideTurnstile && !form.turnstileToken"
            :loading="loading || loginLoading"
            class="w-full"
            type="submit"
            variant="primary"
            @click="onSubmit"
          >
            Log in
          </JbButton>
        </div>

        <div
          v-if="!loggedIn"
          class="w-full"
        >
          <SocialAuth
            type="login"
            @loading="() => loginLoading = true"
          />
        </div>

        <p
          class="w-full text-paragraph font-bold cursor-pointer text-left"
          @click="onClickForgotPassword"
        >
          Forgot Password?
        </p>

        <p class="w-full text-paragraph text-left">
          Don’t have an account?
          <span
            class="font-bold cursor-pointer"
            @click="handleRegister"
          >
            Register an Account.
          </span>
        </p>
      </div>
    </div>
  </form>
</template>
