import { defineComponent, h } from 'vue'
import type { VNode } from 'vue'
import type {
  ExtractComponentProps,
  NotificationSchema,
  Notification
} from './types'
import { Notification as NotificationComponent } from './index'

interface SlotProps<T extends NotificationSchema> {
  notifications: Notification<T>[];
  close: (id: Notification['id']) => void;
}

function defineNotificationComponent<T extends NotificationSchema> () {
  const wrapper = defineComponent(
    (props: ExtractComponentProps<typeof NotificationComponent>, { slots }) => {
      return () => {
        return h(NotificationComponent, props, slots)
      }
    }
  )
  return wrapper as typeof wrapper & {
    new (): {
      $slots: {
        default: (arg: SlotProps<T>) => VNode[];
      };
    };
  }
}

export default defineNotificationComponent
