import { betFields } from './fragments';

export const LIVE_BET_SUBSCRIPTION = gql`
  subscription liveBet {
    liveBet {
      ...betFields
      __typename
    }
  }
  ${betFields}
`;

export const BETS_QUERY = gql`
  query bets(
    $amount_end: Float
    $amount_start: Float
    $ends_at: DateTime
    $starts_at: DateTime
    $game_id: ID
    $limit: Int!
    $payout_start: Float
    $payout_end: Float
    $type: TransactionType!
  ) {
    bets(
      filter: {
        amount_end: $amount_end
        amount_start: $amount_start
        ends_at: $ends_at
        starts_at: $starts_at
        game_id: $game_id
        limit: $limit
        payout_start: $payout_start
        payout_end: $payout_end
        type: $type
      }
    ) {
      paginate {
        page_count
      }
      results {
        ...betFields
        __typename
      }
    }
  }
  ${betFields}
`;
