import type { RuntimeConfig } from "nuxt/schema";
import { ApolloClients } from "@vue/apollo-composable";
// @ts-ignore
import NuxtApollo from "#apollo";

export default defineNuxtPlugin((nuxtApp) => {
  const config: RuntimeConfig = useRuntimeConfig();

  const csrfToken = ref<false | string>(false);
  const authToken = ref<false | string>(false);

  nuxtApp.vueApp.provide(ApolloClients, nuxtApp._apolloClients);

  nuxtApp.provide("csrfToken", () => {
    return {
      token: csrfToken,
      onReady: (fn: (token: string) => void) => {
        return watch(
          () => csrfToken.value,
          (token) => {
            if (token) {
              fn(token);
            }
          },
          { immediate: true }
        );
      },
    };
  });

  nuxtApp.provide("authToken", () => {
    return {
      token: authToken,
      onReady: (fn: (token: string) => void) => {
        return watch(
          () => authToken.value,
          (token) => {
            if (token) {
              fn(token);
            }
          },
          { immediate: true }
        );
      },
    };
  });

  const fetchCsrf =
    process.client &&
    fetch(`${config.public.graphqlBaseUrl}/auth/csrf-token`, {
      ...(config.public.credentialsInclude ? { credentials: "include" } : {}),
    })
      .then(async (res) => {
        const json = await res.clone().json();
        try {
          csrfToken.value = json["x-csrf-token"];
        } catch (e) {}

        return res;
      })
      .catch((e) => Promise.reject(e));

  const clientConfig = NuxtApollo.clients.default;

  // @ts-ignore add csrf header
  nuxtApp.hook("apollo:csrf", async ({ token, client }) => {
    if (client !== "default") {
      return;
    }

    // Check if SSR
    if (process.server) {
      return;
    }

    if (csrfToken.value) {
      token.value = csrfToken.value;

      return;
    }

    // @ts-ignore
    const res = await fetchCsrf?.catch(() => false);
    if (!res) {
      return;
    }

    token.value = csrfToken.value;
  });

  // pass token to cms hygraph client
  // @ts-ignore
  nuxtApp.hook("apollo:auth", ({ token, client }) => {
    if (client !== "cms") {
      return;
    }

    if (token.value) {
      return;
    }

    token.value = config.public.cmsHygraphToken;
  });

  // decodeURIComponent token which is encoded when to store in cookie manually
  // @ts-ignore
  nuxtApp.hook("apollo:auth", ({ token, client }) => {
    if (client !== "default") {
      return;
    }

    if (token.value) {
      authToken.value = token.value;
      return;
    }

    const tokenName = clientConfig.tokenName!;
    const encodedToken =
      clientConfig?.tokenStorage === "cookie"
        ? useCookie(tokenName).value
        : (process.client && localStorage.getItem(tokenName)) || undefined;

    if (encodedToken) {
      token.value = decodeURIComponent(encodedToken);
      authToken.value = token.value;
    }
  });

  // @ts-ignore
  nuxtApp.hook("apollo:link", ({ context, prevContext, client }) => {
    if (client !== "default") {
      return;
    }
    // no need extra context on SSR
    if (process.server) {
      return;
    }

    context.value = {
      ...prevContext,
    };
  });

  // @ts-ignore
  nuxtApp.hook("apollo:error", ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(message);
      });
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });
});
