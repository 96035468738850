<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    variant?: 'default' | 'primary' | 'alternative' | 'text';
    size?: 'lg' | 'md' | 'sm';
    stretch?: boolean;
    loading?: boolean;
    disabled?: boolean;
  }>(),
  {
    variant: 'default',
    size: 'lg',
    stretch: false,
    loading: false,
    disabled: false,
  },
);

const stretchClass = props.stretch ? 'inline-block' : '';
const variantClass = computed(() => {
  const variantToClassMap = {
    default: '',
    primary: 'bg-primary-gradient',
    alternative: 'bg-for-g-2',
    text: 'border-lime-gray',
  };
  return variantToClassMap[props.variant];
});

const buttonSizeClass = computed(() => {
  const sizeToClassMap = {
    lg: 'px-2 py-3 h-[54px] rounded-primary',
    md: 'px-[17.7px] py-3 max-h-10 rounded-md',
    sm: 'px-3 py-2 text-sm',
  };
  return sizeToClassMap[props.size];
});
</script>
<template>
  <button
    type="button"
    class="
      inline-flex justify-center items-center gap-2 font-bold
      outline-none ring-0 hover:ring-0 shadow-none
      focus:outline-none focus:ring-0 active:outline-none active:ring-0
      focus:shadow-none active:shadow-none
      disabled:bg-green-950 disabled:bg-none disabled:cursor-not-allowed
      disabled:active:scale-100 disabled:text-disabled-paragraph
      disabled:border-[2px] disabled:border-line disabled:border-solid
    "
    :class="[
      variantClass,
      stretchClass,
      buttonSizeClass
    ]"
    :disabled="disabled || loading"
  >
    <svg
      v-if="loading"
      class="animate-spin h-6 w-6 text-green-600"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      />
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>

    <slot v-else />
  </button>
</template>
