export const USER_WALLETS_QUERY = gql`
  query userWallets($first: Int!, $type: CurrencyType!) {
    userWallets(first: $first, type: $type) {
      data {
        created_at
        fiatquotes
        currency_code
        balance
        id
        locked_balance
        updated_at
        user_id
        vaulted_balance
        currency {
          id
          name
          code
          symbol
          current_price
          subunits
          quotes {
            values
          }
        }
        activeAddress {
          id
          wallet_id
          status
          address
          currency_code
        }
      }
    }
  }
`;

export const WALLET_UPDATED_SUBSCRIPTION = gql`
  subscription walletUpdated {
    walletUpdated {
      id
    }
  }
`;

export const WHITE_LIST_ADDRESS_QUERY = gql`
  query whitelistAddresses($first: Int!, $page: Int) {
    whitelistAddresses(first: $first, page: $page) {
      data {
        address
        address_name
        currency
      }
    }
  }
`;
