export const userFields = gql`
  fragment userFields on User {
    id
    avatar
    created_at
    display_name
    email
    email_verified_at
    enable2fa
    incomplete_profile
    name
    total_affiliates
    updated_at
    username
    free_spins
  }
`;

export const tempUserFields = gql`
  fragment tempUserFields on TempUser {
    id
    email
    fingerprint_hash
    is_verified
    sequence
  }
`;

export const gameFields = gql`
  fragment gameFields on Game {
    id
    slug
    cover
    enabled
    house_edge
    image
    released_at
    title
  }
`;

export const gameCategoryFields = gql`
  fragment gameCategoryFields on GameCategory {
    id
    name
    slug
  }
`;

export const betFields = gql`
  fragment betFields on Bet {
    id
    action_id
    amount
    bet_conditions
    bet_multiplier
    client_seed
    created_at
    currency_code
    cursor
    display_name
    final_results
    game
    high_roller
    issued_at
    multiplier
    nonce
    normalized_amount
    normalized_payout
    payout
    processed_at
    server_seed_hashed
    server_seed_unhashed
    transaction_id
    updated_at
    __typename
  }
`;

export const addressFields = gql`
  fragment addressFields on Address {
    country_id
    city_id
    formatted_address
    latitude
    longitude
    number
    postalcode
    state_id
    street
  }
`;
