<template>
  <div
    class="
      w-full h-full fixed top-0 left-0 bg-black
      flex items-center justify-center
      backdrop-blur-4 blur-[3px]
    "
  >
    <div class="blackhole" />
    <div class="text">
      404
    </div>
  </div>
</template>
<style lang="css" scoped>
.blackhole, .blackhole:before, .blackhole:after {
  animation: anim 7s linear infinite;
  @apply flex text-white rounded-[50%];
}

@keyframes anim {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(-360deg)
  }
}

.blackhole {
  box-shadow: 1vh 0 3vh 2vh #f50,
  inset -2vh 0 3vh 4vh #f80,
  -1vh -1vh 35vh 0 #f60;
  @apply relative w-[50vh] h-[50vh] blur-md;
}

.blackhole:before, .blackhole:after {
  content: '';
  @apply bg-white relative top-[6vh];
}

.text {
  transform: translate(-50%, -50%);
  @apply absolute font-bold text-[12vh] text-[#b9b9b9] animate-none top-1/2 left-1/2 tracking-[5px];
}

@keyframes stationary {
  0% {
    transform: translate(-50%, -50%)
  }
  100% {
    transform: translate(-50%, -50%)
  }
}
</style>
