<template>
  <div
    class="text-left flex gap-x-2 items-center"
    :class="[typeClass]"
  >
    <slot />
  </div>
</template>
<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    type?: 'error' | 'info' | 'success';
  }>(),
  { type: 'error' },
);
const typeClassMap = {
  error: 'text-red-600 text-sm font-semibold',
  info: 'text-sm',
  success: 'text-paragraph text-sm',
};
const typeClass = computed(() => typeClassMap[props.type]);
</script>
