<script setup lang="ts">
import AuthProvider from '~/services/providers/AuthProvider.vue';
import BaseProvider from '~/services/providers/BaseProvider.vue';
import { cn } from '~/utils';
import NotificationProvider from '~/services/providers/NotificationProvider.vue';

useHead({
  htmlAttrs: {
    class: cn('h-full scroll-smooth'),
  },
  bodyAttrs: {
    class: cn('bg-chip/50 font-proximanova w-full h-full p-2 bg-body-md lg:bg-body-lg xl:bg-body bg-cover bg-center'),
  },
});

useSeoMeta({
  titleTemplate: 'Junglebet | %s',
  ogTitle: 'Junglebet | Best Online Crypto Casino and Bitcoin Sports Betting',
  ogDescription: 'Enjoy the ultimate crypto gambling experience with low fees and instant payouts. Junglebet offers unmatched casino games and sports betting options. Sign up now!',
});
</script>
<template>
  <BaseProvider>
    <NotificationProvider>
      <AuthProvider>
        <NuxtLayout :level="1">
          <NuxtPage />
        </NuxtLayout>
      </AuthProvider>
    </NotificationProvider>
  </BaseProvider>
</template>
