<script lang="ts" setup>
import { cn } from '~/utils';
import { FACEBOOK_BUTTON_ICON, GOOGLE_BUTTON_ICON } from '~/icons/icons';
import { GET_SOCIAL_URL_QUERY } from '~/graphql';

const { type } = defineProps<{
  type: string;
}>();

const emits = defineEmits<{
  (e: 'loading'): void;
}>();

const route = useRoute();
const affiliateCode = computed(() => (route.query.p || ''));

const onSocialOAuth = async (provider: string) => {
  emits('loading');
  const { data } = await useAsyncQuery<{
    getSocialContent: {
      url: string;
    };
  }>(GET_SOCIAL_URL_QUERY, {
    provider,
  });
  if (affiliateCode.value !== '') {
    window.localStorage.setItem('JunglebetPromoCode', affiliateCode.value);
  }
  window.location.href = data.value?.getSocialContent.url;
};
</script>

<template>
  <ClientOnly>
    <JbInputMessage
      :class="cn([
        'justify-center gap-x-2 w-full'
      ])"
      type="success"
    >
      Or {{ type }} with
      <svg
        class="w-[29px] h-[28px] text-white cursor-pointer"
        @click="onSocialOAuth('facebook')"
      >
        <use :xlink:href="FACEBOOK_BUTTON_ICON" />
      </svg>
      <svg
        class="w-[31px] h-[28px] text-white cursor-pointer"
        @click="onSocialOAuth('google')"
      >
        <use :xlink:href="GOOGLE_BUTTON_ICON" />
      </svg>
    </JbInputMessage>
  </ClientOnly>
</template>
