<script setup lang="ts">
import { uuid } from 'vue-uuid';
import { useTimeoutFn } from '@vueuse/core';
import useFingerprint from '~/composables/auth/useFingerprint';
import { INIT_LOADING, SHARED_DATA } from '~/constants';
import { USER_COUNT_SUBSCRIPTION, USERS_COUNT } from '~/graphql';
import AnimateLogo from '~/atoms/AnimateLogo.vue';

const fingerprintToken = ref<string>('');
const { loading, result, subscribeToMore } = useQuery(USERS_COUNT, {});
const usersCount = computed(
  () => result.value?.getSharedData?.players_count || 0,
);
const wageredAmount = computed(() => result.value?.getSharedData?.wagered || 0);
const initialRendering = ref(true);
const loaded = ref(false);
const mounted = ref(false);
const loadings = ref<string[]>([]);

const ready = computed<boolean>(
  () =>
    !initialRendering.value || (mounted.value && loadings.value.length === 0),
);

const onInitialRendering = (callback: () => void) => {
  if (initialRendering.value && process.client) {
    callback();
  }
};

const addLoading = (maxTimeout = 3000) => {
  const loadingID = uuid.v4();
  loadings.value = [...loadings.value, loadingID];
  const removeLoading = () => {
    loadings.value = loadings.value.filter(l => l !== loadingID);
  };

  // remove loading after max timeout
  setTimeout(() => {
    removeLoading();
  }, maxTimeout);

  return removeLoading;
};

const useAddLoading = (maxTimeout = 3000) => {
  const loadingID = uuid.v4();

  const add = () => {
    loadings.value = [...loadings.value, loadingID];
    // remove loading after max timeout
    setTimeout(() => {
      remove();
    }, maxTimeout);
  };

  const remove = () => {
    loadings.value = loadings.value.filter(l => l !== loadingID);
  };

  return {
    add,
    remove,
  };
};

watch(
  () => ready.value,
  (r) => {
    if (r) {
      initialRendering.value = false;
    }
  },
);

onMounted(async () => {
  loaded.value = true;
  const { visitorToken } = await useFingerprint();
  fingerprintToken.value = visitorToken;
  subscribeToMore({
    document: USER_COUNT_SUBSCRIPTION,
    variables: { channelId: 'users-count' },
    updateQuery (prev, { subscriptionData }) {
      const updatedCount = subscriptionData.data?.userCountUpdated;
      if (!updatedCount) {
        return prev;
      }

      return {
        getSharedData: {
          ...prev.getSharedData,
          players_count: updatedCount.players_count,
          wagered: updatedCount.wagered,
        },
      };
    },
  });

  useTimeoutFn(() => {
    mounted.value = !loading.value && !!fingerprintToken.value;
  }, 50);
});

provide(INIT_LOADING, {
  addLoading,
  useAddLoading,
  mounted,
  ready,
  loadings,
  initialRendering,
  onInitialRendering,
});

provide(SHARED_DATA, {
  fingerprintToken,
  usersCount,
  wageredAmount,
});
</script>

<template>
  <div
    v-if="!loaded"
    class="
      fixed top-0 left-0 z-[999]
      bg-chip w-screen h-screen overflow-hidden
      flex justify-center items-center
    "
  >
    <JbLogo class="w-[170px] md:w-[320px]" />
  </div>

  <Transition
    name="loadingFade"
    enter-active-class="duration-0"
    enter-from-class="opacity-100"
    enter-to-class="opacity-100"
    leave-active-class="duration-500 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="!ready"
      class="
        fixed top-0 left-0 z-[1000]
        bg-chip w-screen h-screen overflow-hidden
        flex justify-center items-center
      "
    >
      <AnimateLogo
        class="w-[170px] md:w-[320px]"
      />
    </div>
  </Transition>
  <slot />
</template>
