import { gameCategoryFields, gameFields } from './fragments';

export const GAME_QUERY = gql`
  query game($id: ID, $slug: String) {
    game(id: $id, slug: $slug) {
      ...gameFields
      gameCategories {
        ...gameCategoryFields
      }
      gameProducer {
        id
        enabled
        name
        display_name
        slug
        image
      }
    }
  }
  ${gameFields}
  ${gameCategoryFields}
`;

export const CHIP_PROMO_FREE_SPIN_MUTATION = gql`
  mutation chipPromoWheelSpin{
    chipPromoWheelSpin{
      id
      balance
      betAmount
      delta
      matchId
      nonce
      payout
      payoutMultiplier
      result
      status
      transactionId
    }
  }
`;
