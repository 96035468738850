export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, height=device-height, initial-scale=1, maximum-scale=1"},{"charset":"utf-8"},{"property":"name","name":"name","content":"Junglebet"},{"http-equiv":"X-UA-Compatible","content":"IE=edge"},{"http-equiv":"Reply-to","content":"support@junglebet.com"},{"name":"description","content":"Enjoy the ultimate crypto gambling experience with low fees and instant payouts. Junglebet offers unmatched casino games and sports betting options. Sign up now!"},{"name":"format-detection","content":"telephone=no"},{"name":"google-site-verification","content":"bU8hwjEjsXY0IrmrI15lVW3cKACKcQTV0xECEZ5X9YI"},{"name":"msvalidate.01","content":"D9F854468005F9096AFA97EDA15E90A0"},{"name":"robots","content":"all"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"property":"url","name":"url","content":"https://junglebet.com"},{"property":"og:locale","name":"og:locale","content":"en_US"},{"property":"og:type","name":"og:type","content":"website"},{"property":"og:title","name":"og:title","content":"Junglebet | Best Online Crypto Casino and Bitcoin Sports Betting"},{"property":"og:description","name":"og:description","content":"Enjoy the ultimate crypto gambling experience with low fees and instant payouts. Junglebet offers unmatched casino games and sports betting options. Sign up now!"},{"property":"og:url","name":"og:url","content":"https://junglebet.com"},{"property":"og:site_name","name":"og:site_name","content":"Junglebet"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.png"},{"rel":"canonical","href":"https://junglebet.com"},{"rel":"preload","as":"style","href":"https://use.typekit.net/nkb0byu.css","onload":"this.rel='stylesheet'"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en","dir":"ltr"},"title":"Junglebet","charset":"utf-8","viewport":"width=device-width, height=device-height, initial-scale=1, maximum-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false