<script lang="ts" setup>
import { useVuelidate } from '@vuelidate/core';
import { minLength, required, sameAs } from '@vuelidate/validators';
import { useMutation } from '@vue/apollo-composable';
import { EyeIcon, EyeSlashIcon, LockClosedIcon } from '@heroicons/vue/20/solid';
import { AUTH, ERRORS, LOGIN } from '~/constants';
import { RESET_PASSWORD_MUTATION } from '~/graphql';
import { CIRCLE_CHECK_ICON, SMALL_CIRCLE_WARNING_ICON } from '~/icons/icons';
import { checkLetters, checkNumberAndSymbol, cn, parseGraphqlError } from '~/utils';
import { notify } from '~/atoms';
import FormField from '~/components/molecules/form/FormField.vue';
import JInput from '~/atoms/JInput.vue';

const { hideTurnstile }: any = inject(AUTH);
const runtimeConfig = useRuntimeConfig();
const route = useRoute();
const router = useRouter();
const token = computed(() => route.query.token);
const emailAddress = computed(() => decodeURIComponent(route.query.email));
const verifyCode = ref<string>('');
const password = ref<string>('');
const confirmPassword = ref<string>('');
const turnstileToken = ref(hideTurnstile.value || null);

const rules = {
  password: {
    required,
    minLength: minLength(8),
    checkNumberAndSymbol,
    checkLetters,
  },
  confirmPassword: {
    required,
    sameAs: sameAs(password),
  },
  turnstileToken: {
    required,
  },
};

const v$ = useVuelidate(rules, {
  password,
  confirmPassword,
  turnstileToken,
});

const showPassword = ref<boolean>(false);

const {
  mutate,
  loading: resetLoading,
  onDone,
  onError,
} = useMutation(RESET_PASSWORD_MUTATION);

const onSubmit = async () => {
  if (!(await v$.value.$validate()) || resetLoading.value) {
    return;
  }

  await mutate({
    password: password.value,
    password_confirmation: confirmPassword.value,
    token: token.value,
    email: emailAddress.value,
    code: verifyCode.value,
  });
};

onDone(async ({ data }) => {
  notify.success({
    title: 'Success!',
    description: data.resetPassword[0],
  });

  router.push({
    path: router.currentRoute.value.path,
    query: {
      ...router.currentRoute.value.query,
      type: LOGIN,
      modal: 'auth',
    },
  });
});

onError((error) => {
  const parsedError = parseGraphqlError(error);
  notify.error({
    title: 'Failed Reset Password',
    description: parsedError.message || 'Something went wrong. Please try again!',
  });
});

const updatePassword = async () => {
  await v$.value.password.$validate();
};

const updateConfirmPassword = async () => {
  await v$.value.confirmPassword.$validate();
};

const togglePasswordVisible = () => {
  showPassword.value = !showPassword.value;
};
</script>

<template>
  <form
    class="RegisterForm"
    @submit.prevent="onSubmit()"
  >
    <div
      class="w-full h-full"
    >
      <p
        class="
          text-lg md:text-xxl font-bold leading-[145%] text-[#F8F5F6] text-left w-full
        "
      >
        Create a new password
      </p>

      <div class="w-full flex flex-col gap-3 items-start mt-3 text-xs md:text-sm">
        <FormField
          :class="v$.password.$error ? cn('!border-red-600') : (v$.password.$dirty ? cn('!border-highlights') : '')"
          class="w-full hover:border-highlights"
        >
          <JInput
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            placeholder="New Password"
            @update:model-value="updatePassword"
          >
            <template #startIcon>
              <LockClosedIcon
                :class="v$.password.$error ? cn('!text-red-600') : (v$.password.$dirty ? cn('!text-zinc-100') : '')"
                class="w-4 h-4 text-paragraph"
              />
            </template>
            <template #endIcon>
              <button
                class="w-4 h-4"
                @click="togglePasswordVisible"
              >
                <template v-if="!showPassword">
                  <EyeIcon
                    :class="v$.password.$error ? cn('!text-red-600') : (v$.password.$dirty ? cn('!text-zinc-100') : '')"
                    class="w-4 h-4 text-paragraph"
                  />
                </template>
                <template v-else>
                  <EyeSlashIcon
                    :class="v$.password.$error ? cn('!text-red-600') : (v$.password.$dirty ? cn('!text-zinc-100') : '')"
                    class="w-4 h-4 text-paragraph"
                  />
                </template>
              </button>
            </template>
          </JInput>
        </FormField>

        <JbInputMessage v-if="v$.password.$error">
          <svg
            class="w-3 h-3 text-red-600"
          >
            <use :xlink:href="SMALL_CIRCLE_WARNING_ICON" />
          </svg>

          {{
            v$.password.minLength.$invalid
              ? ERRORS.PASSWORD_MIN_LENGTH
              : v$.password.checkLetters.$invalid
                ? ERRORS.PASSWORD_LETTERS
                : v$.password.checkNumberAndSymbol.$invalid
                  ? ERRORS.PASSWORD_SYMBOL_NUMBER
                  : ERRORS.PASSWORD_REQUIRED
          }}
        </JbInputMessage>

        <FormField
          :class="v$.confirmPassword.$error ? cn('!border-red-600') : (v$.confirmPassword.$dirty ? cn('!border-highlights') : '')"
          class="w-full hover:border-highlights"
        >
          <JInput
            v-model="confirmPassword"
            :type="showPassword ? 'text' : 'password'"
            placeholder="Confirm New Password"
            @update:model-value="updateConfirmPassword"
          >
            <template #startIcon>
              <LockClosedIcon
                :class="v$.confirmPassword.$error ? cn('!text-red-600') : (v$.confirmPassword.$dirty ? cn('!text-zinc-100') : '')"
                class="w-4 h-4 text-paragraph"
              />
            </template>
            <template #endIcon>
              <button
                class="w-4 h-4"
                @click="togglePasswordVisible"
              >
                <template v-if="!showPassword">
                  <EyeIcon
                    :class="v$.confirmPassword.$error ? cn('!text-red-600') : (v$.confirmPassword.$dirty ? cn('!text-zinc-100') : '')"
                    class="w-4 h-4 text-paragraph"
                  />
                </template>
                <template v-else>
                  <EyeSlashIcon
                    :class="v$.confirmPassword.$error ? cn('!text-red-600') : (v$.confirmPassword.$dirty ? cn('!text-zinc-100') : '')"
                    class="w-4 h-4 text-paragraph"
                  />
                </template>
              </button>
            </template>
          </JInput>
        </FormField>

        <JbInputMessage v-if="v$.confirmPassword.$error">
          <svg
            class="w-3 h-3 text-red-600"
          >
            <use :xlink:href="SMALL_CIRCLE_WARNING_ICON" />
          </svg>
          Passwords do not match. Please try again.
        </JbInputMessage>

        <div v-if="password.length > 0">
          <JbInputMessage
            type="info"
          >
            <svg
              :class="
                !v$.password.minLength.$invalid
                  ? 'text-primary'
                  : 'text-red-600'
              "
              class="w-3 h-3"
            >
              <use :xlink:href="CIRCLE_CHECK_ICON" />
            </svg>
            Min. 8 characters in length
          </JbInputMessage>
          <JbInputMessage
            class="mt-2"
            type="info"
          >
            <svg
              :class="
                !v$.password.checkNumberAndSymbol.$invalid
                  ? 'text-primary'
                  : 'text-red-600'
              "
              class="w-3 h-3"
            >
              <use :xlink:href="CIRCLE_CHECK_ICON" />
            </svg>
            Min. 1 number and symbol
          </JbInputMessage>
          <JbInputMessage
            class="mt-2"
            type="info"
          >
            <svg
              :class="
                !v$.password.checkLetters.$invalid
                  ? 'text-primary'
                  : 'text-red-600'
              "
              class="w-3 h-3"
            >
              <use :xlink:href="CIRCLE_CHECK_ICON" />
            </svg>
            Uses lowercase/uppercase letters
          </JbInputMessage>
        </div>

        <NuxtTurnstile
          v-if="!hideTurnstile"
          v-model="turnstileToken"
          :site-key="runtimeConfig.public.turnstileSiteKey"
        />

        <JbInputMessage v-if="v$.turnstileToken.$error">
          <svg
            class="w-3 h-3 text-red-600"
          >
            <use :xlink:href="SMALL_CIRCLE_WARNING_ICON" />
          </svg>

          Turnstile reCAPTCHA is required.
        </JbInputMessage>

        <div class="w-full">
          <JbButton
            :disabled="(!hideTurnstile && !turnstileToken) || !password || !confirmPassword"
            :loading="resetLoading"
            class="w-full"
            type="submit"
            variant="primary"
            @click="onSubmit"
          >
            Submit
          </JbButton>
        </div>
      </div>
    </div>
  </form>
</template>
