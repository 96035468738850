export const [
  LOGIN,
  FINISH_REGISTRATION,
  NEEDS_MERGE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  WELCOME_USER,
] = [
  'login',
  'finish_registration',
  'needs_merge',
  'forgot_password',
  'reset_password',
  'welcome',
] as const;

export * from './base';
export * from './injectSymbols';
export * from './errors';
