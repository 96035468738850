import { createNotifier, defineNotificationComponent, NotificationGroup } from '@/services/libs/notification';
import { CIRCLE_WARNING_ICON, SMALL_CIRCLE_CHECK_ICON } from '~/icons/icons';

export type NotificationSchema = {
  type: 'error' | 'warning' | 'success' | 'info'; // NOTE: only error is supported for now
  title: string;
  description: string;
  color?: string;
  border?: string;
  icon?: {
    class: string;
    href: string;
  }
};
const defaultTypeIconMap = {
  error: {
    href: CIRCLE_WARNING_ICON,
    class: 'w-[14px] h-[14px] text-red-600',
  },
  success: {
    href: SMALL_CIRCLE_CHECK_ICON,
    class: 'w-[14px] h-[14px] text-green-600',
  },
  warning: {
    href: CIRCLE_WARNING_ICON,
    class: 'w-[14px] h-[14px] text-amber-600',
  },
  info: {
    href: CIRCLE_WARNING_ICON,
    class: 'w-[14px] h-[14px] text-zinc-100',
  },
};
export type JbNotification = NotificationSchema & {
  group?: 'top-left';
};
export const doNotify = createNotifier<NotificationSchema>();
export const notify = {
  error: (schema: Omit<JbNotification, 'type'>, timeout?: number) =>
    doNotify(
      {
        group: 'bottom-right',
        type: 'error',
        icon: defaultTypeIconMap.error,
        color: 'bg-red-600',
        border: 'border-red-600',
        ...schema,
      },
      timeout || 5000,
    ),
  success: (schema: Omit<JbNotification, 'type'>, timeout?: number) =>
    doNotify(
      {
        group: 'bottom-right',
        type: 'success',
        icon: defaultTypeIconMap.success,
        color: 'bg-green-600',
        border: 'border-green-600',
        ...schema,
      },
      timeout || 5000,
    ),
  warning: (schema: Omit<JbNotification, 'type'>, timeout?: number) =>
    doNotify(
      {
        group: 'bottom-right',
        type: 'warning',
        icon: defaultTypeIconMap.warning,
        color: 'bg-amber-600',
        border: 'border-amber-600',
        ...schema,
      },
      timeout || 5000,
    ),
  info: (schema: Omit<JbNotification, 'type'>, timeout?: number) =>
    doNotify(
      {
        group: 'bottom-right',
        type: 'info',
        icon: defaultTypeIconMap.info,
        color: 'bg-zinc-100',
        border: 'border-zinc-100',
        ...schema,
      },
      timeout || 5000,
    ),
};
export const Notification = defineNotificationComponent<NotificationSchema>();
export { NotificationGroup };
