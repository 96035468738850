export const CIRCLE_CHECK_ICON: string = '#circle-check-icon';
export const USER_CIRCLE_ICON: string = '#user-circle-icon';
export const EXTERNAL_LINK_ICON: string = '#external-link-icon';
export const BASE_CLOSE_ICON: string = '#base-close-icon';
export const NP_LINK_ICON: string = '#np-link-icon';
export const TELEGRAM_ICON: string = '#telegram-icon';
export const TWITTER_X_ICON: string = '#twitter-x-icon';
export const INSTAGRAM_ICON: string = '#instagram-icon';
export const FACEBOOK_ICON: string = '#facebook-icon';
export const FACEBOOK_BUTTON_ICON: string = '#facebook-button-icon';
export const GOOGLE_FILL_ICON: string = '#google-fill-icon';
export const GOOGLE_BUTTON_ICON: string = '#google-button-icon';
export const GOOGLE_ICON: string = '#google-icon';
export const MESSAGE_ICON: string = '#message-icon';
export const SMALL_CIRCLE_WARNING_ICON: string = '#small-circle-warning';
export const CIRCLE_WARNING_ICON: string = '#circle-warning';
export const SMALL_CIRCLE_CHECK_ICON: string = '#small-circle-check';
export const CLOSE_X_ICON: string = '#close-x-icon';
export const INPUT_SEARCH_ICON: string = '#input-search-icon';
export const WALLET_ICON: string = '#wallet-icon';
export const LINK_ICON: string = '#link-icon';
export const CLIPBOARD_ICON: string = '#clipboard-icon';
export const BAR_CHART_ICON: string = '#bar-chart-icon';
export const SHARE_SOCIAL_ICON: string = '#share-social-icon';
export const SHIELD_OK: string = '#shield-ok';
