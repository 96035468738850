<script lang="ts" setup>
import { useVuelidate } from '@vuelidate/core';
import { email as validEmail, maxLength, minLength, required } from '@vuelidate/validators';
import { useMutation } from '@vue/apollo-composable';
import { EnvelopeIcon, EyeIcon, EyeSlashIcon, LockClosedIcon, UserIcon } from '@heroicons/vue/20/solid';
import { AUTH, ERRORS, FINISH_REGISTRATION, SHARED_DATA, WELCOME_USER } from '~/constants';
import { FINISH_REGISTRATION_MUTATION } from '~/graphql';
import { CIRCLE_CHECK_ICON, SMALL_CIRCLE_WARNING_ICON } from '~/icons/icons';
import {
  checkAtLetter,
  checkDotLetter,
  checkLetters,
  checkNameLetters,
  checkNumberAndSymbol,
  cn,
  parseGraphqlError,
} from '~/utils';
import { notify } from '~/atoms';
import FormField from '~/components/molecules/form/FormField.vue';
import JInput from '~/atoms/JInput.vue';

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const {
  username,
  authType,
  hideTurnstile,
  affiliateCode,
  login,
}: any = inject(AUTH);
const runtimeConfig = useRuntimeConfig();
const route = useRoute();

const form = reactive({
  email: '',
  username: '',
  password: '',
  turnstileToken: hideTurnstile.value || null,
});

const rules = {
  email: {
    validEmail,
    required,
    checkAtLetter,
    checkDotLetter,
    minLength: minLength(3),
  },
  username: {
    required,
    minLength: minLength(3),
    maxLength: maxLength(14),
    checkNameLetters,
  },
  password: {
    required,
    minLength: minLength(8),
    checkNumberAndSymbol,
    checkLetters,
  },
  turnstileToken: {
    required,
  },
};

const v$ = useVuelidate(rules, form);
const { onLogin } = useApollo();
const loadingForLogin = ref<boolean>(false);
const { fingerprintToken }: any = inject(SHARED_DATA);
const loggedIn = ref<boolean>(false);
const showPassword = ref<boolean>(false);
const usernameErrorString = ref<string>('');
const promoCode = ref<string>('');
const isVisitor = ref<boolean>(false);
const isUsernameError = computed(() => !!usernameErrorString.value);
const router = useRouter();

const {
  mutate: finishMutate,
  loading: finishLoading,
  onDone: onFinishDone,
  onError: onFinishError,
  error: finishError,
} = useMutation(FINISH_REGISTRATION_MUTATION);

const onFinishRegistration = async () => {
  if (!(await v$.value.$validate()) || loadingForLogin.value) {
    return;
  }

  usernameErrorString.value = '';

  if (affiliateCode.value !== '') {
    promoCode.value = affiliateCode.value;
  }

  if (
    window.localStorage.getItem('JunglebetPromoCode') &&
    promoCode.value === ''
  ) {
    promoCode.value = window.localStorage.getItem('JunglebetPromoCode') || '';
    window.localStorage.removeItem('JunglebetPromoCode');
  }

  if (
    window.localStorage.getItem('jbReferrer') &&
    !isVisitor.value
  ) {
    isVisitor.value = window.localStorage.getItem('jbReferrer') === 'Visitor';
    window.localStorage.removeItem('jbReferrer');
  }

  loadingForLogin.value = true;
  finishMutate({
    hash: route.query.logid,
    email: form.email,
    password: form.password,
    username: form.username,
    fingerprint: fingerprintToken.value,
    sponsorCode: promoCode.value,
    isVisitor: isVisitor.value,
  });
};

const onSubmit = async () => {
  if (authType.value === FINISH_REGISTRATION) {
    onFinishRegistration();
  }
};

onFinishDone(async ({ data }) => {
  try {
    loggedIn.value = true;
    await onLogin(data.finishRegistration.token);
    await login();

    notify.success({
      title: 'Welcome to Junglebet!',
      description: `Enjoy Free Spin “${data.finishRegistration.user.username}”!`,
    });

    loadingForLogin.value = false;
    router.push({
      path: router.currentRoute.value.path,
      query: {
        ...router.currentRoute.value.query,
        type: WELCOME_USER,
        modal: 'auth',
      },
    });
  } catch (e) {
    loadingForLogin.value = false;
    notify.error(
      {
        title: 'Error',
        description: 'Something went wrong. please reload browser',
      },
      4000,
    );
  }
});

onFinishError(({ graphQLErrors, networkError }) => {
  loadingForLogin.value = false;
  if (
    Array.isArray(graphQLErrors) &&
    graphQLErrors[0]?.extensions?.validation
  ) {
    const validationErrors = graphQLErrors[0]?.extensions?.validation;
    if (Array.isArray(validationErrors['input.username'])) {
      usernameErrorString.value = validationErrors['input.username'][0];
    }
  } else {
    const parsedError = parseGraphqlError(finishError.value);
    notify.error({
      title: 'Failed Code Confirmation',
      description: parsedError.message || 'Something went wrong. Please try again!',
    });
  }
});

const updatePassword = async () => {
  await v$.value.password.$validate();
};

const inputUsername = async () => {
  usernameErrorString.value = '';
  await v$.value.username.$validate();
};

const inputEmail = async () => {
  await v$.value.email.$validate();
};

const togglePasswordVisible = () => {
  showPassword.value = !showPassword.value;
};

onMounted(() => {
  form.email = username.value;
});
</script>

<template>
  <form
    class="RegisterForm"
    @submit.prevent="onSubmit()"
  >
    <div
      class="w-full h-full"
    >
      <p
        class="
          text-lg md:text-xxl font-bold leading-[145%] text-[#F8F5F6] text-left w-full
        "
      >
        Finish Registration Process
      </p>

      <div class="w-full flex flex-col gap-3 items-start mt-3 text-xs md:text-sm">
        <FormField
          :class="v$.email.$error ? cn('!border-red-600') : (v$.email.$dirty || authType === FINISH_REGISTRATION ? cn('!border-highlights') : '')"
          class="w-full hover:border-highlights"
        >
          <JInput
            v-model="form.email"
            :readonly="authType === FINISH_REGISTRATION"
            placeholder="Enter email address"
            @update:model-value="inputEmail"
          >
            <template #startIcon>
              <EnvelopeIcon
                :class="v$.email.$error ? cn('!text-red-600') : (v$.email.$dirty ? cn('!text-zinc-100') : '')"
                class="w-4 h-4 text-paragraph"
              />
            </template>
          </JInput>
        </FormField>

        <FormField
          :class="v$.username.$error ? cn('!border-red-600') : (v$.username.$dirty ? cn('!border-highlights') : '')"
          class="w-full hover:border-highlights"
        >
          <JInput
            v-model="form.username"
            placeholder="Create username"
            @update:model-value="inputUsername"
          >
            <template #startIcon>
              <UserIcon
                :class="v$.username.$error ? cn('!text-red-600') : (v$.username.$dirty ? cn('!text-zinc-100') : '')"
                class="w-4 h-4 text-paragraph"
              />
            </template>
          </JInput>
        </FormField>

        <JbInputMessage v-if="v$.username.$error || isUsernameError">
          <svg
            class="w-3 h-3 text-red-600"
          >
            <use :xlink:href="SMALL_CIRCLE_WARNING_ICON" />
          </svg>
          {{
            isUsernameError
              ? usernameErrorString
              : v$.username.minLength.$invalid
                ? ERRORS.USERNAME_MIN_LENGTH
                : v$.username.maxLength.$invalid
                  ? ERRORS.USERNAME_MAX_LENGTH
                  : v$.username.checkNameLetters.$invalid
                    ? ERRORS.USERNAME_INVALID
                    : ERRORS.USERNAME_REQUIRED
          }}
        </JbInputMessage>

        <FormField
          :class="v$.password.$error ? cn('!border-red-600') : (v$.password.$dirty ? cn('!border-highlights') : '')"
          class="w-full hover:border-highlights"
        >
          <JInput
            v-model="form.password"
            :type="showPassword ? 'text' : 'password'"
            placeholder="Create password"
            @update:model-value="updatePassword"
          >
            <template #startIcon>
              <LockClosedIcon
                :class="v$.password.$error ? cn('!text-red-600') : (v$.password.$dirty ? cn('!text-zinc-100') : '')"
                class="w-4 h-4 text-paragraph"
              />
            </template>
            <template #endIcon>
              <button
                class="w-4 h-4"
                @click="togglePasswordVisible"
              >
                <template v-if="!showPassword">
                  <EyeIcon
                    :class="v$.password.$error ? cn('!text-red-600') : (v$.password.$dirty ? cn('!text-zinc-100') : '')"
                    class="w-4 h-4 text-paragraph"
                  />
                </template>
                <template v-else>
                  <EyeSlashIcon
                    :class="v$.password.$error ? cn('!text-red-600') : (v$.password.$dirty ? cn('!text-zinc-100') : '')"
                    class="w-4 h-4 text-paragraph"
                  />
                </template>
              </button>
            </template>
          </JInput>
        </FormField>

        <JbInputMessage v-if="v$.password.$error">
          <svg
            class="w-3 h-3 text-red-600"
          >
            <use :xlink:href="SMALL_CIRCLE_WARNING_ICON" />
          </svg>

          {{
            v$.password.minLength.$invalid
              ? ERRORS.PASSWORD_MIN_LENGTH
              : v$.password.checkLetters.$invalid
                ? ERRORS.PASSWORD_LETTERS
                : v$.password.checkNumberAndSymbol.$invalid
                  ? ERRORS.PASSWORD_SYMBOL_NUMBER
                  : ERRORS.PASSWORD_REQUIRED
          }}
        </JbInputMessage>

        <div v-if="form.password.length > 0">
          <JbInputMessage
            type="info"
          >
            <svg
              :class="
                !v$.password.minLength.$invalid
                  ? 'text-primary'
                  : 'text-red-600'
              "
              class="w-3 h-3"
            >
              <use :xlink:href="CIRCLE_CHECK_ICON" />
            </svg>
            Min. 8 characters in length
          </JbInputMessage>
          <JbInputMessage
            class="mt-2"
            type="info"
          >
            <svg
              :class="
                !v$.password.checkNumberAndSymbol.$invalid
                  ? 'text-primary'
                  : 'text-red-600'
              "
              class="w-3 h-3"
            >
              <use :xlink:href="CIRCLE_CHECK_ICON" />
            </svg>
            Min. 1 number and symbol
          </JbInputMessage>
          <JbInputMessage
            class="mt-2"
            type="info"
          >
            <svg
              :class="
                !v$.password.checkLetters.$invalid
                  ? 'text-primary'
                  : 'text-red-600'
              "
              class="w-3 h-3"
            >
              <use :xlink:href="CIRCLE_CHECK_ICON" />
            </svg>
            Uses lowercase/uppercase letters
          </JbInputMessage>
        </div>

        <NuxtTurnstile
          v-if="!hideTurnstile"
          v-model="form.turnstileToken"
          :site-key="runtimeConfig.public.turnstileSiteKey"
        />

        <JbInputMessage v-if="v$.turnstileToken.$error">
          <svg
            class="w-3 h-3 text-red-600"
          >
            <use :xlink:href="SMALL_CIRCLE_WARNING_ICON" />
          </svg>

          Turnstile reCAPTCHA is required.
        </JbInputMessage>

        <div class="w-full">
          <div
            v-if="loggedIn"
            class="
              text-white text-base font-weight-bold
              inline-flex items-center justify-center gap-x-2
              h-[54px] w-full
            "
          >
            <span>Account has been created!</span>
            <svg
              class="w-5 h-5 text-green-600"
            >
              <use :xlink:href="CIRCLE_CHECK_ICON" />
            </svg>
          </div>
          <JbButton
            v-else
            :disabled="(!hideTurnstile && !form.turnstileToken) || !form.email"
            :loading="finishLoading || loadingForLogin"
            class="w-full"
            type="submit"
            variant="primary"
            @click="onSubmit"
          >
            Register
          </JbButton>
        </div>
      </div>
    </div>
  </form>
</template>
