<script lang="ts" setup>
import { useIntervalFn } from '@vueuse/core';
import { useVuelidate } from '@vuelidate/core';
import { email as validEmail, minLength, required } from '@vuelidate/validators';
import { useMutation } from '@vue/apollo-composable';
import { EnvelopeIcon } from '@heroicons/vue/20/solid';
import { ERRORS } from '~/constants';
import { SEND_RESET_LINK_MUTATION } from '~/graphql';
import { CIRCLE_CHECK_ICON, SMALL_CIRCLE_WARNING_ICON } from '~/icons/icons';
import { checkAtLetter, checkDotLetter, cn, parseGraphqlError } from '~/utils';
import { notify } from '~/atoms';
import FormField from '~/components/molecules/form/FormField.vue';
import JInput from '~/atoms/JInput.vue';

const form = reactive({
  email: '',
});

const rules = {
  email: {
    validEmail,
    required,
    checkAtLetter,
    checkDotLetter,
    minLength: minLength(3),
  },
};

const v$ = useVuelidate(rules, form);

const sentLink = ref<boolean>(false);
const countdownResend = ref<number>(60);

const {
  mutate,
  loading: sendLoading,
  onDone,
  onError,
} = useMutation(SEND_RESET_LINK_MUTATION);

const onSubmit = async () => {
  if (!(await v$.value.$validate()) || sendLoading.value) {
    return;
  }

  mutate({
    email: form.email,
  });
};

const { pause, resume } = useIntervalFn(() => {
  if (countdownResend.value < 1) {
    sentLink.value = false;
    pause();
    countdownResend.value = 60;
  }

  countdownResend.value -= 1;
}, 1000);

onDone((response) => {
  notify.success({
    title: 'Success!',
    description: response?.data?.sendResetLink[0],
  });

  sentLink.value = true;
  countdownResend.value = 60;
  resume();
});

onError((error) => {
  const parsedError = parseGraphqlError(error);
  notify.error({
    title: 'Error!',
    description: parsedError.message || 'Something went wrong. Please try again!',
  });
});

const inputEmail = async () => {
  await v$.value.email.$validate();
};
</script>

<template>
  <form
    class="RegisterForm"
    @submit.prevent="onSubmit()"
  >
    <div
      class="w-full h-full"
    >
      <p
        class="
          text-lg md:text-xxl font-bold leading-[145%] text-[#F8F5F6] text-left w-full
        "
      >
        Send email to reset password
      </p>

      <div class="w-full flex flex-col gap-3 items-start mt-3 text-xs md:text-sm">
        <FormField
          :class="v$.email.$error ? cn('!border-red-600') : (v$.email.$dirty ? cn('!border-highlights') : '')"
          class="w-full hover:border-highlights"
        >
          <JInput
            v-model="form.email"
            placeholder="Enter email address"
            @update:model-value="inputEmail"
          >
            <template #startIcon>
              <EnvelopeIcon
                :class="v$.email.$error ? cn('!text-red-600') : (v$.email.$dirty ? cn('!text-zinc-100') : '')"
                class="w-4 h-4 text-paragraph"
              />
            </template>
          </JInput>
        </FormField>

        <JbInputMessage v-if="v$.email.$error">
          <svg
            class="w-3 h-3 text-red-600"
          >
            <use :xlink:href="SMALL_CIRCLE_WARNING_ICON" />
          </svg>
          {{
            v$.email.checkAtLetter.$invalid
              ? ERRORS.EMAIL_AT_LETTER_REQUIRED
              : v$.email.required.$invalid
                ? ERRORS.EMAIL_REQUIRED
                : ERRORS.EMAIL_INVALID
          }}
        </JbInputMessage>

        <div class="w-full">
          <div
            v-if="sentLink"
            class="
              text-white text-base font-weight-bold
              inline-flex items-center justify-center gap-x-2
              h-[54px] w-full
            "
          >
            <span>Reset Instructions Sent!</span>
            <svg
              class="w-5 h-5 text-green-600"
            >
              <use :xlink:href="CIRCLE_CHECK_ICON" />
            </svg>
          </div>
          <div
            v-if="sentLink"
            class="
              text-white text-sm font-weight-bold w-full
            "
          >
            If you haven't received it yet, please resend in {{ countdownResend }} seconds.
          </div>
          <JbButton
            v-else
            :disabled="!form.email || v$.email.$error"
            :loading="sendLoading"
            class="w-full"
            type="submit"
            variant="primary"
            @click="onSubmit"
          >
            Submit
          </JbButton>
        </div>
      </div>
    </div>
  </form>
</template>
