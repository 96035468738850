<script setup lang="ts">

const props = withDefaults(
  defineProps<{
    type?: string,
    modelValue: string | any,
    placeholder: string,
    readonly?: boolean,
    focus?: () => void,
    blur?: () => void,
    classes?: {
      Input?: string
    } | undefined
  }>(),
  {
    type: 'text',
    placeholder: '',
    readonly: false,
    focus: () => {
    },
    classes: {},
  },
);
const emits = defineEmits(['update:modelValue']);

const onInput = (e: Event) => {
  emits('update:modelValue', (e.target as HTMLInputElement).value);
};

const $slots = useSlots();

const startIconExists = computed(() => !!$slots.startIcon);
</script>

<template>
  <div class="relative w-full transition duration-150 ease-in-out text-paragraph flex items-center gap-x-2">
    <slot name="label" />
    <div
      class="flex items-center absolute h-full text-white left-3 top-[0px] box-border w-min-4 ml-[-1px]"
    >
      <slot name="startIcon" />
    </div>
    <input
      ref="inputRef"
      class="
        bg-chip
        focus:border-line
        block font-inter w-full
        p-2 text-current font-normal h-9 rounded transition
        duration-150 ease-in-out
        focus:shadow-none
        border-0 outline-none focus:outline-none
        ring-0 active:ring-0 hover:ring-0 focus:ring-0
        shadow-none bg-transparent
        disabled:cursor-not-allowed
        disabled:opacity-75
        placeholder-paragraph
        text-zinc-100
      "
      :class="[{
        'pl-10': startIconExists,
        'pl-2': !startIconExists,
      }, classes.Input?? '']"
      :type="type"
      data-search-input="true"
      :value="modelValue"
      :placeholder="placeholder"
      :readonly="readonly"
      @input="onInput"
      @focus="props.focus"
      @blur="props.blur"
    >
    <div class="flex items-center absolute h-full text-white box-border w-min-4 right-[11px] top-[0px]">
      <slot name="endIcon" />
    </div>
  </div>
</template>
