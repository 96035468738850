import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/opt/buildhome/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import vueuse_head_polyfill_M7DKUOwKp5 from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_03YjkxYbK5 from "/opt/buildhome/repo/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import plugin_tbFNToZNim from "/opt/buildhome/repo/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import floating_vue_EIcJ7xiw0h from "/opt/buildhome/repo/.nuxt/floating-vue.mjs";
import script_YrMPz5ICtR from "/opt/buildhome/repo/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import plugin_nNfA6Lmcbb from "/opt/buildhome/repo/node_modules/@junglebet/apollo-client/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_UzIc2qvBnC from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import maska_EdO7pVjy2z from "/opt/buildhome/repo/src/plugins/maska.ts";
import referrer_4Wv6viNFna from "/opt/buildhome/repo/src/plugins/referrer.ts";
import apollo_DX069Ju2nS from "/opt/buildhome/repo/src/plugins/apollo.ts";
import floating_vue_client_GUTnE83sa9 from "/opt/buildhome/repo/src/plugins/floating-vue.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  vueuse_head_polyfill_M7DKUOwKp5,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_03YjkxYbK5,
  plugin_tbFNToZNim,
  floating_vue_EIcJ7xiw0h,
  script_YrMPz5ICtR,
  plugin_nNfA6Lmcbb,
  chunk_reload_client_UciE0i6zes,
  cross_origin_prefetch_client_UzIc2qvBnC,
  maska_EdO7pVjy2z,
  referrer_4Wv6viNFna,
  apollo_DX069Ju2nS,
  floating_vue_client_GUTnE83sa9
]