<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { BASE_CLOSE_ICON } from '~/icons/icons';

const props = withDefaults(
  defineProps<{
    isOpen: boolean;
    className?: string;
    height?: number;
    isModal?: boolean;
    noHeader?: boolean;
  }>(),
  { isOpen: false, className: '', height: 392, isModal: false, noHeader: true },
);

const isOpeningStarted = ref<boolean>(false);
watch(
  () => props.height,
  () => {
    isOpeningStarted.value = true;
    setTimeout(() => {
      isOpeningStarted.value = false;
    }, 300);
  },
);

const emit =
  defineEmits<{
    (e: 'close:simpleModal'): void;
  }>();

const handleCloseModal = () => {
  emit('close:simpleModal');
};

const onPreventClick = (event: Event) => {
  event.preventDefault();
};

const onClose = () => {
  if (!props.isModal) {
    handleCloseModal();
  }
};

const onClickBackdrop = (event: Event) => {
  if (!props.isModal) {
    handleCloseModal();
  }
};
</script>
<template>
  <TransitionRoot
    appear
    :show="isOpen"
    as="template"
  >
    <Dialog
      as="div"
      class="relative z-[300]"
      @close="onClose"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-neutral-950/40 backdrop-blur-sm"
          @click="onClickBackdrop"
        />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-start md:items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="
                transform bg-for-g-2
                flex flex-col
                rounded-[11.4px]
                transition-all duration-200 ease-in-out
                relative min-w-[320px]
                text-white
              "
              :class="[
                isOpeningStarted ? 'overflow-hidden' : '',
                className
              ]"
              @click.stop="onPreventClick"
            >
              <div class="min-h-[35px] w-full relative flex justify-between z-10">
                <slot name="header" />

                <div
                  class="w-5 h-5 py-1 pl-1 ml-auto"
                  :class="{
                    'mr-4': noHeader
                  }"
                >
                  <svg
                    class="
                      text-zinc-100 cursor-pointer
                      w-full h-full
                    "
                    @click="handleCloseModal()"
                  >
                    <use :xlink:href="BASE_CLOSE_ICON" />
                  </svg>
                </div>
              </div>

              <slot />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
