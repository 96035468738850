<script setup lang="ts">
import { CONTEXT_KEY } from './constants';
import type { Context } from './types';

export interface Props {
  group?: string;
  position?: 'bottom' | 'top';
}

const props = withDefaults(defineProps<Props>(), {
  group: '',
  position: 'bottom',
});

provide<Context>(CONTEXT_KEY, {
  group: props.group,
  position: props.position,
});
</script>

<template>
  <slot :group="group" />
</template>
