import type { ComputedRef, InjectionKey, Ref } from 'vue';
import type { User, Vault, Wallet, Whitelist } from '~/types';

export const AUTH = Symbol('AUTH') as InjectionKey<{
  authType: ComputedRef<string>;
  username: Ref<string>;
  password: Ref<string>;
  affiliateCode: ComputedRef<string>;
  turnstileToken: Ref<boolean | string | undefined>;
  hideTurnstile: ComputedRef<boolean>;
  loading: Ref<boolean>;
  isVisibleProfile: Ref<boolean>;
  isVisibleModal: ComputedRef<boolean>;
  isLoggedIn: ComputedRef<boolean>;
  visitorId: Ref<string>;
  me: ComputedRef<User | null>;
  login: () => void;
  refetch: () => void;
  openAuthModal: () => void;
}>;

export const PROMO_WHEEL_SPIN = Symbol('PROMO_WHEEL_SPIN') as InjectionKey<{
  tempUserId: Ref<number | null>;
  email: Ref<string | null>;
  verificationCode: Ref<string | null>;
  sequence: Ref<number>;
  isVerified: Ref<boolean>;
  isAvailable: Ref<boolean>;
  showFreeSpin: Ref<boolean>;
  showRegisterAddress: Ref<boolean>;
  agreeTos: Ref<boolean>;
  spinResult: Ref<number>;
  degreeVal: Ref<number>;
  wheelSpin: ComputedRef<any>;
}>;

export const WALLET = Symbol('WALLET') as InjectionKey<{
  currentWallet: ComputedRef<Wallet>;
  whiteLists: ComputedRef<Whitelist[]>;
  selectedAddress: Ref<string>;
  vaults: ComputedRef<Vault[]>;
  fee: Ref<number>;
  wallets: ComputedRef<Wallet[]>;
  loading: Ref<boolean>;
  ready: ComputedRef<boolean>;
}>;
